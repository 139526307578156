import { computed, ref, toValue } from 'vue'
import { debouncedRef } from '@vueuse/core'
import { useApiInfinite, useApiInfiniteSkip } from '@lasso/shared/hooks'
import { RefOrGetter } from '@lasso/shared/types'

import { AudienceTarget, AudienceType, ChannelType, SearchAudiencesQuery, useActivationApi } from '@lasso/api-activation/activation'

export const useSearch = ({
  channelId,
  accountId,
  types,
  targets,
}: {
  channelId: RefOrGetter<ChannelType>
  accountId: RefOrGetter<number>
  types: RefOrGetter<AudienceType[]>
  targets: RefOrGetter<AudienceTarget[]>
  enabled?: RefOrGetter<boolean>
}) => {
  const searchQuery = ref('')
  const searchQueryDebounced = debouncedRef(searchQuery, 300)
  const searchQueryFormatted = computed(() => searchQueryDebounced.value.trim())
  const searching = computed(() => Boolean(searchQueryFormatted.value))

  const api = useApiInfinite(
    useActivationApi().searchAudiences,
    (page): [SearchAudiencesQuery] => [{
      channel: toValue(channelId),
      searchQuery: searchQueryFormatted.value,
      advertiserId: toValue(accountId),
      types: toValue(types),
      targets: toValue(targets),
      paging: {
        skip: page.param,
        pageSize: page.size,
      },
    }],
    {
      ...useApiInfiniteSkip(({ responseData }) => responseData.total ?? 0),
      pageSize: 25,
      getDataArray: data => data.data ?? [],
      cache: true,
      refetch: true,
      enabled: searching,
    },
  )

  return {
    ...api,
    searching,
    searchQuery,
    searchQueryFormatted,
  }
}

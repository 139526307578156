<template>
  <Paper variant="filled" color="base" pr="4" :pl="draggable ? '2' : '4'" py="4" rounded="xl" class="bg-base-200!">
    <Box flex alignItems="center" space="2">
      <Icon v-if="draggable" icon="drag_indicator" class="cursor-move" />

      <Box space="0.5" flex col grow="grow">
        <Box flex alignItems="center" justify="between" space="2">
          <Box flex alignItems="center" space="2">
            <Chip data-test-id="selected-audience-id" size="xs" variant="outlined" :label="item.id" />
            <Chip v-if="cpm" data-test-id="selected-audience-cpm" size="xs" variant="outlined">
              {{ cpm }}
            </Chip>

            <Typography data-test-id="selected-audience-name" variant="form-label" wordBreak="all">
              {{ item.name }}
            </Typography>
          </Box>

          <Box flex alignItems="center" space="2">
            <Toggle
              v-if="targetable"
              v-model="included"
              data-test-id="selected-audience-toggle"
              :readonly="targetDisabled"
              size="xs"
              color="success"
              offColor="error"
            />

            <ButtonIcon
              data-test-id="selected-audience-remove"
              :disabled="isDeleteDisabled"
              icon="close"
              @click="emit('removeItem')"
            />
          </Box>
        </Box>

        <Divider variant="dashed" disableMargin />

        <Box flex wrap="wrap">
          <ItemInfo v-if="activeReach" label="Active reach">
            {{ activeReach }}
          </ItemInfo>
          <ItemInfo v-if="target" label="Target">
            {{ target }}
          </ItemInfo>
          <ItemInfo v-if="type" label="Audience type">
            {{ type }}
          </ItemInfo>
          <ItemInfo v-if="created" label="Created">
            {{ created }}
          </ItemInfo>
        </Box>
      </Box>
    </Box>
  </Paper>
</template>

<script setup lang="ts">
import { FormatEnum, formatDate, formatNumber } from '@lasso/shared/utils'
import { TimeZone } from '@lasso/shared/consts'
import { Box, ButtonIcon, Chip, Divider, DraggableNestedItemProps, Icon, Paper, Toggle, Typography } from '@lasso/luikit'
import { computed } from 'vue'
import { NormalizedItem } from '@lasso/shared/hooks'

import { audienceTargetLabels, audienceTypeLabels } from '../../../../shared'
import { useCardAudiences } from '../useCardAudiences'

import { ItemProps, SelectedGroup, SelectedItem } from './types'
import ItemInfo from './ItemInfo.vue'

const {
  draggable,
  targetable,
  targetDisabled,
  disabled,
} = defineProps<DraggableNestedItemProps<SelectedItem, SelectedGroup> & ItemProps>()

const emit = defineEmits<{
  removeItem: []
}>()

const item = defineModel<NormalizedItem<SelectedItem>>('item', { required: true })

const { getInitialAudience, shouldHideCpm, buildAudienceCpm } = useCardAudiences()!

const included = computed({
  get: () => !item.value.excluded,
  set: (value) => {
    item.value = { ...item.value, excluded: !value }
  },
})

const isDeleteDisabled = computed(() => {
  const initialAudience = getInitialAudience(item.value.id)

  return Boolean(initialAudience) && disabled
})

const cpm = computed(() => {
  return shouldHideCpm.value ? '' : formatNumber(buildAudienceCpm(item.value), FormatEnum.Money2)
})

const activeReach = computed(() => {
  const { matchedDeviceRecords } = item.value

  return matchedDeviceRecords ? formatNumber(matchedDeviceRecords, FormatEnum.Int) : ''
})

const type = computed(() => {
  const { audienceType } = item.value

  return audienceType ? audienceTypeLabels[audienceType] ?? String(audienceType) : ''
})

const target = computed(() => {
  const { audienceTargetId } = item.value

  return audienceTargetId ? audienceTargetLabels[audienceTargetId] ?? String(audienceTargetId) : ''
})

const created = computed(() => {
  const { createdDate } = item.value

  return createdDate ? formatDate(createdDate, 'longDateAbbr', TimeZone.EST) : ''
})
</script>

import { array, boolean, mixed, number, object, string } from 'yup'
import {
  AdGroupSourceType,
  AdGroupTriggerEngagement,
  ChannelType,
  DirectSoldAudienceTargetType,
} from '@lasso/api-activation/activation'
import { objGroupBy, objValues } from '@lasso/shared/utils'
import {
  WhenCondition,
  extendSchema,
  extendSchemaShape, oneOfEnum,
} from '@lasso/shared/validation'
import { uniq } from 'lodash-es'

import {
  AudienceEngagement, AudienceEngagementAction,
  AudienceLogicConditions, CardAudienceSchemaShape,
  SelectedAudience,
} from './types'

export const buildCardAudiencesSchema = ({
  whenChannelId,
  isCardEnabled,
  audienceTargetingType = null,
}: {
  whenChannelId: WhenCondition
  isCardEnabled: boolean
  audienceTargetingType?: DirectSoldAudienceTargetType | null
}): CardAudienceSchemaShape => {
  const audienceBaseSchema = {
    audiences: array(mixed<SelectedAudience>().default({} as SelectedAudience)).default([]),
    platformId: oneOfEnum(ChannelType),
    accountId: string().default(''),
    cpm: number().nullable().default(null),
    newAudienceName: string().default(''),
    automaticallyPushAudience: boolean().default(true),
  }

  const lassoTriggerBaseSchema = {
    sourceTypeId: oneOfEnum([...objValues(AdGroupSourceType), null]).nullable().default(null),
    sourceGroups: array(string().default('')).default([]),
    triggerEngagementId: oneOfEnum([...objValues(AdGroupTriggerEngagement), null]).nullable().default(null),
    engagements: array(mixed<AudienceEngagement>().default({} as AudienceEngagement)).default([]),
    daysAfterEmailDelivered: number().nullable().default(null),
    logicCondition: oneOfEnum(AudienceLogicConditions).default(AudienceLogicConditions.AND),
  }

  const baseSchema = {
    audience: object(audienceBaseSchema).required(),
    isLassoTrigger: boolean().default(false),
    lassoTrigger: object(lassoTriggerBaseSchema).required(),
  }

  if (!isCardEnabled) {
    return baseSchema
  }

  const audienceSchema = extendSchemaShape(audienceBaseSchema, {
    audiences: (schema) => {
      schema = schema.test('noRepeatsInGroup', 'Audience cannot be grouped with itself', (value) => {
        return objValues(objGroupBy(value, audience => audience.groupId))
          .map(group => group.map(audience => audience.id))
          .every(ids => uniq(ids).length === ids.length)
      })

      const errorMessage = 'Unable to change fee as ad group is or has already been activated'

      switch (audienceTargetingType) {
        case DirectSoldAudienceTargetType.HCP:
          return schema.min(1, `${errorMessage}. Add at least one audience.`)

        case DirectSoldAudienceTargetType.OPEN_TARGETING:
          return schema.max(0, `${errorMessage}. Remove all audiences.`)

        default:
          return schema
      }
    },
  })

  const audienceDataOnlySchema = extendSchemaShape(audienceBaseSchema, {
    platformId: schema => schema.required(),
    accountId: schema => schema.required(),
    cpm: schema => schema.min(0).required(),
    automaticallyPushAudience: schema => schema.required(),
  })

  return extendSchemaShape(baseSchema, {
    audience: schema => whenChannelId(
      schema,
      {
        is: ChannelType.DATA_ONLY,
        then: schema => schema.when('isLassoTrigger', {
          is: true,
          then: () => object(audienceDataOnlySchema).required(),
          otherwise: () => extendSchema(audienceDataOnlySchema, {
            audiences: schema => schema.min(1).max(1).required(),
          }),
        }),
        otherwise: () => object(audienceSchema).required(),
      },
    ),
    lassoTrigger: schema => schema
      .when('isLassoTrigger', {
        is: true,
        then: () => extendSchema(lassoTriggerBaseSchema, {
          sourceTypeId: schema => schema.required(),
          sourceGroups: schema => schema.min(1).required(),
          triggerEngagementId: schema => schema.required(),
          engagements: schema => schema
            .when('triggerEngagementId', {
              is: AdGroupTriggerEngagement.CUSTOM_DYNAMIC,
              then: schema => schema.of(
                object({
                  action: oneOfEnum([...objValues(AudienceEngagementAction), null]).nullable().required(),
                  quantity: number().nullable().required().min(1),
                  periodValue: number().nullable().required().minMax(1, 60),
                }),
              ).min(1).max(2),
            }),
          daysAfterEmailDelivered: schema => schema
            .when('triggerEngagementId', {
              is: AdGroupTriggerEngagement.UN_OPEN,
              then: schema => schema.min(0).max(999).required(),
            }),
        }),
      }).required(),
  })
}

<template>
  <FormSection
    :id="getId('audience.audiences')"
    :title="maxAudiences === 1 ? 'Selected audience' : 'Selected audiences'"
  >
    <Box flex col space="4" class="relative" :height="empty ? '162px' : undefined">
      <EmptyState
        v-if="empty"
        class="empty"
        message="To get started, drag an audience from above."
        data-test-id="empty-state"
      />
      <DraggableNested
        v-model="audienceGroups"
        class="pb-6 -mb-6"
        data-test-id="selected-audience-draggable"
        :group="draggableGroup"
        :maxDepth="1"
        :draggableProps="{
          handle: `[data-role=${draggableDataRole}]`,
          spaceY: '2',
          swapThreshold: 0.3,
        }"
        :convertItem="convertItem"
        :addItem="addItem"
        :itemComponent="SelectedAudiencesItem"
        :itemProps="{
          draggable: maxAudiences > 1 && !disabled && selectedAudiences.length > 1,
          targetable,
          targetDisabled: disabled,
          disabled,
        }"
        :emptyInsertThreshold="10"
      />
      <Alert v-if="shouldShowNotice" color="warning" data-test-id="direct-sold-warning">
        Current HCP audience targeting selections will be open to all users. Switch to an AND operator to both negatively
        and positively target HCP audiences.
      </Alert>
    </Box>
  </FormSection>
</template>

<script setup lang="ts">
import { Alert, Box, DraggableNested, DraggableNestedConvertItem, EmptyState, FormSection } from '@lasso/luikit'
import { computed, toRef } from 'vue'
import { omit, partition } from 'lodash-es'
import { AudienceInfo } from '@lasso/api-activation/activation'

import { objGroupBy, objValues } from '@lasso/shared/utils'

import { useCardAudiences } from '../useCardAudiences'
import { SelectedAudience } from '../types'
import { draggableDataRole, draggableGroup } from '../consts'

import { SelectedGroup, SelectedItem } from './types'
import SelectedAudiencesItem from './SelectedAudiencesItem.vue'

const {
  getId,
  audience,
  isDataOnly,
  isDirectSold,
  maxAudiences,
  canSelectAudiences,
} = useCardAudiences()!

const selectedAudiences = toRef(audience, 'audiences')

const disabled = computed(() => !canSelectAudiences.value)
const empty = computed(() => selectedAudiences.value.length === 0)
const targetable = computed(() => !isDataOnly.value)

const shouldShowNotice = computed(() => {
  if (!isDirectSold) {
    return false
  }

  const topLevelAudiences = objValues(
    objGroupBy(selectedAudiences.value, audience => audience.groupId),
  )
    .filter(group => group.length <= 1)
    .flat()

  return partition(
    topLevelAudiences,
    audience => audience.excluded,
  ).every(part => part.length > 0)
})

const getStartingGroupId = (audiences: Array<{ groupId: number | null }>) => {
  return audiences.reduce((highestGroupId, audience) => Math.max(highestGroupId, audience.groupId ?? -1), 0) + 1
}

const buildItem = (audience: SelectedAudience, groupId: number): SelectedItem => {
  return {
    ...audience,
    groupId,
    type: 'item',
  }
}

const buildAudience = (item: SelectedItem, groupId: number): SelectedAudience => {
  return {
    ...omit(item, ['type', 'items']),
    groupId,
  }
}

const audienceGroups = computed({
  get: (): SelectedGroup | SelectedItem => {
    const items = selectedAudiences.value.map(audience => buildItem(audience, audience.groupId))
    const groups = items.reduce((groups, item) => {
      const group = groups.get(item.groupId) ?? []
      group.push(item)
      groups.set(item.groupId, group)

      return groups
    }, new Map<number, SelectedItem[]>())
    const itemsGrouped = Array.from(groups.entries()).map(([groupId, group]): SelectedGroup | SelectedItem => {
      if (group.length === 1) {
        return group[0]!
      }

      return {
        groupId,
        items: group,
        type: 'list',
        op: 'and',
      }
    })

    return {
      groupId: null,
      items: itemsGrouped,
      type: 'list',
      op: 'or',
    }
  },
  set: (value) => {
    let groupIdCounter = getStartingGroupId(value.items ?? [])
    const existingGroupIds = new Set<number>()

    const audiences = (value.items ?? []).flatMap((group) => {
      let groupId = group.groupId ?? groupIdCounter++
      // Make sure ungrouping audiences doesn't end up with them belonging to the same group
      while (existingGroupIds.has(groupId)) {
        groupId = groupIdCounter++
      }
      existingGroupIds.add(groupId)

      if (group.type === 'list') {
        return group.items.map(item => buildAudience(item as SelectedItem, groupId))
      }
      else {
        return buildAudience(group, groupId)
      }
    })

    selectedAudiences.value = audiences
  },
})

const convertItem: DraggableNestedConvertItem<SelectedGroup | SelectedItem> = (item) => {
  if (item.type === 'list') {
    return null
  }

  return {
    groupId: null,
    type: 'list',
    op: 'and',
  }
}

const addItem = (item: unknown): SelectedItem => {
  // The items get dragged in from AvailableAudiences component
  const _item = item as AudienceInfo

  return {
    type: 'item',
    id: _item.id,
    name: _item.fullName,
    audienceType: _item.audienceType,
    audienceTargetId: _item.target,
    createdDate: _item.createdDate,
    displayCpm: _item.displayCPM ?? 0,
    socialCpm: _item.socialCPM ?? 0,
    matchedDeviceRecords: _item.matchedDeviceRecords ?? 0,
    groupId: getStartingGroupId(selectedAudiences.value),
    excluded: false,
  }
}
</script>

<style scoped>
.empty {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>

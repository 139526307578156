<template>
  <Box width="100%">
    <Box flex col space="4">
      <Transitions group>
        <template v-for="(engagement, index) of engagements.fields" :key="engagement.key">
          <CustomTriggerEngagementsItem :index="index" :disabled="disabled" />

          <Box v-if="!engagement.isLast" flex justify="center">
            <LogicConditionSelect :disabled="disabled" />
          </Box>
        </template>
      </Transitions>
    </Box>

    <Box v-if="canAddEngagement && !disabled" mt="4">
      <AddLink data-test-id="custom-engagement-add" @click="addEngagement()">
        Engagement
      </AddLink>
    </Box>
  </Box>
</template>

<script setup lang="ts">
import {
  AddLink,
  Box,
  Transitions,
} from '@lasso/luikit'

import { computed } from 'vue'

import { useCardAudiences } from '../useCardAudiences'

import CustomTriggerEngagementsItem from './CustomTriggerEngagementsItem.vue'
import LogicConditionSelect from './LogicConditionSelect.vue'

const {
  engagements,
  addEngagement,
  canAddEngagement,
  isDraft,
} = useCardAudiences()!

const disabled = computed(() => !isDraft.value)
</script>

<template>
  <FormSection :id="getId('lassoTrigger.sourceGroups')" width="100%">
    <template #title>
      <Box flex space="1" alignItems="center">
        <FormFieldLabel variant="h6" color="textPrimary">
          Select trigger source
        </FormFieldLabel>
        <ErrorState
          v-if="validationApi.error"
          data-test-id="source-ag-validation-error"
          :error="validationApi.error"
          inline
          py="0"
          @retry="validationApi.retry()"
        />
        <CircularProgress v-else-if="validationApi.loading" data-test-id="source-ag-validation-loading" />
      </Box>
    </template>

    <ErrorState
      v-if="sourceAdGroupsApi.error"
      data-test-id="source-ag-error"
      :error="sourceAdGroupsApi.error"
      inline
      @retry="sourceAdGroupsApi.retry()"
    />
    <Box v-else>
      <Skeleton v-if="sourceAdGroupsApi.loading" data-test-id="source-ag-skeleton" height="411px" />
      <SelectPanel v-else data-test-id="source-ag-select-panel" :empty="filteredSourceAdGroups.length === 0">
        <template #search>
          <SelectPanelInput v-model="searchQuery" />
        </template>

        <template #items>
          <SelectPanelVirtualScroller
            v-slot="{ item }"
            :items="filteredSourceAdGroups"
            keyField="adGroupID"
          >
            <SelectPanelItem
              v-model="lassoTrigger.sourceGroups"
              :value="String(item.adGroupID)"
              :chip="item.adGroupID"
              :searchQuery="searchQuery"
              :label="item.adGroupName!"
              :disabled="!isDraft"
              singleLine
            />
          </SelectPanelVirtualScroller>
        </template>

        <template #selectedItemsTitle>
          Selected ad groups
        </template>

        <template v-if="selectedListItemsReverse.length > 0" #selectedItems>
          <SelectPanelSelectedItem
            v-for="item in selectedListItemsReverse"
            :key="item.adGroupID"
            :chip="item.adGroupID"
            :label="item.adGroupName!"
            :disabled="!isDraft"
            @remove="onSourceGroupRemove(item)"
          />
        </template>
      </SelectPanel>
    </Box>

    <Alert v-if="validationWarning" data-test-id="source-ag-validation-warning" color="warning" mt="4">
      {{ validationWarning }}
    </Alert>
  </FormSection>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import {
  Alert,
  Box, CircularProgress, ErrorState,
  FormFieldLabel,
  FormSection,
  SelectPanel,
  SelectPanelInput,
  SelectPanelItem,
  SelectPanelSelectedItem,
  SelectPanelVirtualScroller,
  Skeleton,
} from '@lasso/luikit'
import { AdGroupSource, ChannelType, GetSourceAdGroupsQuery, useActivationApi } from '@lasso/api-activation/activation'
import { useApi } from '@lasso/shared/hooks'

import { truthy } from '@lasso/shared/utils'

import { useCardAudiences } from '../useCardAudiences'

const api = useActivationApi()

const {
  lassoTrigger,
  marketerId,
  accountId,
  channelId,
  isDraft,
  getId,
  audience,
} = useCardAudiences()!

const searchQuery = ref('')

const sourceAdGroupsApi = reactive(useApi(
  api.getSourceAdGroups,
  (): [GetSourceAdGroupsQuery] | null => {
    /* v8 ignore next 3 - the component isn't rendered if this isn't set */
    if (!lassoTrigger.sourceTypeId) {
      return null
    }

    return [{
      marketerId: marketerId.value,
      advertiserId: accountId.value,
      sourceType: lassoTrigger.sourceTypeId,
      campaignId: null,
      keyword: null,
    }]
  },
  { refetch: true },
))

const sourceAdGroups = computed(() => sourceAdGroupsApi.data?.data ?? [])

const filteredSourceAdGroups = computed(() => {
  const query = searchQuery.value.trim().toLowerCase()

  return sourceAdGroups.value.filter((adGroup) => {
    return String(adGroup.adGroupID) === query || adGroup.adGroupName?.toLowerCase().includes(query)
  })
})

const selectedSourceAdGroups = computed(() => {
  return lassoTrigger.sourceGroups
    .map(id => sourceAdGroups.value.find(adGroup => id === String(adGroup.adGroupID)))
    .filter(truthy)
})

const selectedListItemsReverse = computed(() => selectedSourceAdGroups.value.slice().reverse())

const onSourceGroupRemove = (group: AdGroupSource) => {
  lassoTrigger.sourceGroups = lassoTrigger.sourceGroups.filter(id => id !== String(group.adGroupID))
}

const validationApi = reactive(useApi(
  api.getSourceAdGroupsValidate,
  () => {
    if (lassoTrigger.sourceGroups.length === 0) {
      return null
    }

    return [{
      marketerId: marketerId.value,
      advertiserId: accountId.value,
      adGroupIDs: lassoTrigger.sourceGroups.map(id => Number(id)),
    }]
  },
  { refetch: true, immediate: false, clearWhenDisabled: true, enabled: isDraft },
))

const validationWarning = computed(() => {
  if (!validationApi.data || !validationApi.loaded) {
    return ''
  }

  return validationApi.data.success ? '' : validationApi.data.message ?? ''
})

validationApi.onData(({ success, emailMaxDataCPM, xandrMaxDataCPM }) => {
  if (!success) {
    return
  }

  const dataCpm = channelId.value === ChannelType.EMAIL ? emailMaxDataCPM : xandrMaxDataCPM

  if (dataCpm) {
    audience.cpm = Number(dataCpm.toFixed(2))
  }
})
</script>

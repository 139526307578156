import { AudienceType } from '@lasso/api-activation/activation'
import { objEntries, objFromEntries, objGroupBy } from '@lasso/shared/utils'
import { v4 as uuidv4 } from 'uuid'

import {
  AudienceAccount,
  AudienceAccountsRecord,
  AudienceEngagement,
  AudienceEngagementAction,
  AudienceExternalAccount,
  PlatformId,
  SelectedAudience,
} from './types'

type ParsedEngagement = {
  action: AudienceEngagementAction
  quantity: number
  days?: number
  weeks?: number
  months?: number
}

export const parseEngagements = (jsonString: string): AudienceEngagement[] => {
  if (!jsonString) {
    return []
  }

  let parsedEngagements: ParsedEngagement[]

  try {
    parsedEngagements = JSON.parse(jsonString)
  }
  catch {
    return []
  }

  return parsedEngagements.map((item, index) => {
    let periodValue = item.days ?? 0

    // Weeks and months aren't available in the UI
    if (item.weeks) {
      periodValue += item.weeks * 7
    }

    if (item.months) {
      periodValue += item.months * 30
    }

    return {
      id: index.toString(),
      action: item.action,
      quantity: item.quantity || 0,
      periodValue,
    }
  })
}

export const parseExternalAccounts = (externalAccounts: AudienceExternalAccount[], accountId: string): {
  accounts: AudienceAccountsRecord
  platformId: PlatformId | undefined
} => {
  const accountsByChannel = objGroupBy(externalAccounts, account => account.channel)

  const accounts = objFromEntries(
    objEntries(accountsByChannel).map(([channel, accounts]) => {
      return [
        channel,
        accounts.map((account): AudienceAccount => ({
          id: account.accId,
          type: account.accType,
        })),
      ]
    }),
  )

  let platformId: PlatformId | undefined

  if (accountId) {
    const platform = externalAccounts.find(account => account.accId === accountId)

    if (platform) {
      platformId = platform.channel
    }
  }

  return { accounts, platformId }
}

export const hasAudiencesForRetargeting = (audiences: SelectedAudience[]) => {
  return audiences.some((audience) => {
    return [AudienceType.CUSTOM, AudienceType.RETARGETING].includes(audience.audienceType)
  })
}

export const buildEngagement = (): AudienceEngagement => {
  return {
    id: uuidv4(),
    action: null,
    periodValue: null,
    quantity: null,
  }
}

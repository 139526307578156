import {
  AdGroupSourceType,
  AdGroupTriggerEngagement,
  AudienceTarget,
  AudienceType,
  ChannelType,
} from '@lasso/api-activation/activation'
import { RequiredSchemaShapeOf } from '@lasso/shared/validation'

/* Form */
export type CardAudienceFormData = {
  audience: AudienceFormData
  isLassoTrigger: boolean
  lassoTrigger: LassoTriggerFormData
}

export type CardAudienceSchemaShape = RequiredSchemaShapeOf<CardAudienceFormData, CardAudienceSchemaExactTypes>

export type CardAudienceSchemaExactTypes = SelectedAudience | AudienceEngagement

export type AudienceFormData = {
  audiences: SelectedAudience[]
  platformId: ChannelType | undefined
  accountId: string
  cpm: number | null
  newAudienceName: string
  automaticallyPushAudience: boolean
}

export type LassoTriggerFormData = {
  sourceTypeId: AdGroupSourceType | null
  sourceGroups: string[]
  triggerEngagementId: AdGroupTriggerEngagement | null
  engagements: AudienceEngagement[]
  daysAfterEmailDelivered: number | null
  logicCondition: AudienceLogicConditions
}

export type SelectedAudience = {
  id: number
  name: string
  audienceType: AudienceType
  audienceTargetId?: AudienceTarget
  createdDate: string
  displayCpm: number
  socialCpm: number
  matchedDeviceRecords: number

  groupId: number
  excluded: boolean
}

/* Data only */
export type PlatformId =
  | ChannelType.TWITTER
  | ChannelType.FACEBOOK_INSTAGRAM
  | ChannelType.LINKED_IN
  | ChannelType.TIK_TOK
  | ChannelType.SNAPCHAT
  | ChannelType.XANDR_DMP
  | ChannelType.LIVERAMP
  | ChannelType.EXTERNAL_DSP
  | ChannelType.TRADE_DESK_DMP

export type AudienceAccountType = 'advertiser' | 'marketer'

export type AudienceExternalAccount = {
  accId: string
  accType: AudienceAccountType
  channel: PlatformId
}

export type AudienceAccount = {
  id: string
  type: AudienceAccountType
}

export type AudienceAccountsRecord = Partial<Record<ChannelType, AudienceAccount[]>>

/* Lasso Triggers */
export type AudienceEngagement = {
  id: string
  action: AudienceEngagementAction | null
  quantity: number | null
  periodValue: number | null
}

// This gets saved to db directly without validation!
export enum AudienceEngagementAction {
  Viewed = 'Viewed',
  Clicked = 'Clicked',
}

export enum AudienceLogicConditions {
  AND = 'AND',
  OR = 'OR',
}

<template>
  <Box data-test-id="selected-audience-item" class="item" flex col space="2">
    <Box
      v-if="!isFirst"
      flex
      alignItems="center"
      justify="center"
    >
      <Chip color="primary" size="xs" variant="filled" :label="op.toUpperCase()" />
    </Box>
    <Paper
      class="!bg-base-200"
      v-bind="wrapperProps"
      :data-role="draggable ? draggableDataRole : undefined"
    >
      <Box v-if="props.item.type === 'list'" flex col alignItems="center">
        <slot />
      </Box>
      <template v-else>
        <Item
          v-bind="props"
          v-model:item="item"
          @removeItem="emit('removeItem')"
        />
        <slot />
      </template>
    </Paper>
  </Box>
</template>

<script setup lang="ts">
import { NormalizedItem } from '@lasso/shared/hooks'
import { Box, Chip, DraggableNestedItemProps, Paper } from '@lasso/luikit'
import { computed } from 'vue'

import { ComponentProps } from '@lasso/shared/types'

import { draggableDataRole } from '../consts'

import { ItemProps, SelectedGroup, SelectedItem } from './types'
import Item from './Item.vue'

const props = defineProps<
  (DraggableNestedItemProps<SelectedGroup> | DraggableNestedItemProps<SelectedItem, SelectedGroup>)
  & ItemProps
>()

const emit = defineEmits<{
  removeItem: []
}>()

const item = defineModel<NormalizedItem<SelectedItem>>('item', { required: true })

const op = computed(() => {
  return props.parentItem.type === 'list' ? props.parentItem.op : /* v8 ignore next */ ''
})

const wrapperProps = computed((): ComponentProps<typeof Paper> & ComponentProps<typeof Box> => {
  const isList = props.item.type === 'list'

  return {
    color: 'base',
    variant: 'filled',
    rounded: 'xl',
    shadow: 'none',
    p: isList ? '2' : undefined,
  }
})
</script>

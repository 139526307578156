<template>
  <Card title="Audience" collapse opened withDivider>
    <template v-if="!loading && !error" #headerActions>
      <FormChoiceField
        :id="getId('isLassoTrigger')"
        v-slot="{ id, disabled }"
        label="Triggers"
        :disabled="!isDraft"
      >
        <Toggle
          :id="id"
          v-model="isLassoTrigger"
          :disabled="disabled"
          size="xs"
          color="secondary"
        />
      </FormChoiceField>
    </template>

    <Skeleton v-if="loading" height="633px" />
    <ErrorState v-else-if="error" :error="error" @retry="retry()" />
    <Box v-else flex col space="6">
      <AudienceTriggers v-if="isLassoTrigger" />
      <AudienceDataOnly v-if="isDataOnly" />
      <AvailableAudiences v-if="canSelectAudiences" :withTitle="isLassoTrigger || isDataOnly" />
      <SelectedAudiences v-if="canSelectAudiences || audience.audiences.length > 0" />
    </Box>
  </Card>
</template>

<script setup lang="ts">
import { Box, Card, ErrorState, FormChoiceField, Skeleton, Toggle } from '@lasso/luikit'

import { useCardAudiences } from './useCardAudiences'
import { AudienceTriggers } from './AudienceTriggers'
import { SelectedAudiences } from './SelectedAudiences'
import { AvailableAudiences } from './AvailableAudiences'
import { AudienceDataOnly } from './AudienceDataOnly'

const {
  loading,
  error,
  retry,
  isDataOnly,
  isLassoTrigger,
  isDraft,
  audience,
  canSelectAudiences,
  getId,
} = useCardAudiences()!
</script>

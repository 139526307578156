import { RefOrGetter } from '@lasso/shared/types'
import { AudienceCategoryInfo, AudienceInfo } from '@lasso/api-activation/activation'
import { computed, toRef } from 'vue'
import { FormatEnum, formatNumber, truthy } from '@lasso/shared/utils'

import { useCardAudiences } from '../useCardAudiences'

import { getIsAudience } from './utils'

export const useItemData = (_item: RefOrGetter<AudienceInfo | AudienceCategoryInfo>) => {
  const item = toRef(_item)
  const { shouldHideCpm, buildAudienceCpm } = useCardAudiences()!

  const cpm = computed(() => {
    /* v8 ignore next 3 */
    if (!getIsAudience(item.value)) {
      return 0
    }

    return buildAudienceCpm({
      displayCpm: item.value.displayCPM ?? 0,
      socialCpm: item.value.socialCPM ?? 0,
    })
  })

  const chips = computed(() => {
    if (!getIsAudience(item.value)) {
      return undefined
    }

    return [item.value.id, !shouldHideCpm.value && formatNumber(cpm.value, FormatEnum.Money2)].filter(truthy)
  })

  const info = computed(() => {
    return (getIsAudience(item.value) && item.value.matchedDeviceRecords)
      ? `Active Reach: ${formatNumber(item.value.matchedDeviceRecords, FormatEnum.Int)}`
      : undefined
  })

  const hasSegmentationFilter = computed(() => getIsAudience(item.value) && item.value.hasSegmentationFilter)

  return {
    chips,
    info,
    hasSegmentationFilter,
  }
}

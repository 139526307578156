<template>
  <FormSection title="Select platform">
    <Box flex col space="6">
      <FormField v-if="availablePlatforms.length > 0" :id="getId('audience.platformId')" v-slot="{ id }">
        <Box flex space="6" wrap="wrap">
          <RadioButton
            v-for="platform in availablePlatforms"
            :key="platform.id"
            v-model="audience.platformId"
            data-test-id="data-only-platform-id-radio"
            :name="id"
            :value="platform.id"
            :title="platform.title"
            :disabled="platformIdDisabled"
          >
            <template v-if="platform.iconComponent" #icon>
              <Icon>
                <component :is="platform.iconComponent" />
              </Icon>
            </template>
          </RadioButton>
        </Box>
      </FormField>

      <Alert
        v-else
        :id="getId('audience.platformId')"
        color="error"
        title="Something went wrong!"
        maxWidth="856px"
      >
        In order to select a platform to push your audience to, please navigate
        to the advertiser's
        <Link :to="`/admin/accounts/advertisers/${accountId}/edit`">
          Account Settings
        </Link>
        and save the Account ID for the platform.
      </Alert>

      <FormField
        :id="getId('audience.accountId')"
        v-slot="{ id, disabled }"
        :disabled="accountIdDisabled || platformIdDisabled"
        label="Account ID"
        width="344px"
        required
      >
        <Select
          :id="id"
          v-model="audience.accountId"
          :options="platformAccounts"
          :disabled="disabled"
          :hideArrow="accountIdDisabled"
          placeholder="Select"
        />
      </FormField>
    </Box>
  </FormSection>

  <FormSection title="Customize audience name">
    <Box flex justify="start" space="6">
      <FormField
        :id="getId('audience.newAudienceName')"
        v-slot="{ id, disabled }"
        :disabled="nameDisabled"
        label="New audience name"
        width="160px"
      >
        <InputText :id="id" v-model="audience.newAudienceName" :disabled="disabled" />
      </FormField>

      <FormField
        :id="getId('audience.cpm')"
        v-slot="{ id }"
        label="Preferred Data CPM"
        width="160px"
      >
        <InputText :id="id" v-model="audience.cpm" currency :mask="decimalMask" />
      </FormField>

      <Box mt="8">
        <FormChoiceField
          :id="getId('audience.automaticallyPushAudience')"
          v-slot="{ id }"
          label="Automatically push audience"
        >
          <Toggle :id="id" v-model="audience.automaticallyPushAudience" size="xs" color="success" />
        </FormChoiceField>
      </Box>
    </Box>
  </FormSection>
</template>

<script setup lang="ts">
import {
  Alert,
  Box,
  FormChoiceField,
  FormField, FormSection,
  Icon, InputText,
  Link,
  RadioButton,
  Select,
  Toggle,
} from '@lasso/luikit'
import { decimalMask } from '@lasso/shared/consts'
import { computed } from 'vue'
import { ChannelType } from '@lasso/api-activation/activation'

import { useCardAudiences } from '../useCardAudiences'
import { PlatformId } from '../types'

import IconCustomFacebookOutlined from '~icons/custom/facebook-outlined'
import IconCustomLinkedinOutlined from '~icons/custom/linkedin-outlined'
import IconCustomSnapchatOutlined from '~icons/custom/snapchat-outlined'
import IconCustomTikTokOutlined from '~icons/custom/tik-tok-outlined'
import IconCustomXOutlined from '~icons/custom/x-outlined'
import IconCustomXandrOutlined from '~icons/custom/xandr-outlined'

const {
  accountId,
  isDataOnly,
  isActive,
  audience,
  accountIdDisabled,
  platformAccounts,
  platformIdDisabled,
  availablePlatformIds,
  getId,
} = useCardAudiences()!

const nameDisabled = computed(() => isDataOnly.value && isActive.value)

const platforms = [
  {
    id: ChannelType.TWITTER,
    title: 'X',
    iconComponent: IconCustomXOutlined,
  },
  {
    id: ChannelType.FACEBOOK_INSTAGRAM,
    title: 'Facebook',
    iconComponent: IconCustomFacebookOutlined,
  },
  {
    id: ChannelType.LINKED_IN,
    title: 'Linkedin',
    iconComponent: IconCustomLinkedinOutlined,
  },
  {
    id: ChannelType.TIK_TOK,
    title: 'TikTok',
    iconComponent: IconCustomTikTokOutlined,
  },
  {
    id: ChannelType.SNAPCHAT,
    title: 'Snapchat',
    iconComponent: IconCustomSnapchatOutlined,
  },
  {
    id: ChannelType.XANDR_DMP,
    title: 'Xandr DMP',
    iconComponent: IconCustomXandrOutlined,
  },
  {
    id: ChannelType.LIVERAMP,
    title: 'Liveramp',
  },
  {
    id: ChannelType.EXTERNAL_DSP,
    title: 'Xandr Curate',
    iconComponent: IconCustomXandrOutlined,
  },
] satisfies Array<{ id: PlatformId; title: string; iconComponent?: unknown }>

const availablePlatforms = computed(() => platforms.filter(item => availablePlatformIds.value.includes(item.id)))
</script>

<template>
  <CategoryWrapper
    :items="data"
    :hasNextPage="hasNextPage"
    :requestNextPage="requestNextPage"
    :error="error"
  >
    <CategoryItem
      v-for="item in data"
      :key="`${item.type}-${item.id}`"
      v-slot="{ nextLevel, nextCategory }"
      :item="item"
      :level="level"
      :expanded="expanded"
      :disabled="disabled"
    >
      <Category
        :channelId="channelId"
        :accountId="accountId"
        :category="nextCategory"
        :types="types"
        :targets="targets"
        :level="nextLevel"
        :disabled="disabled"
      />
    </CategoryItem>
  </CategoryWrapper>
</template>

<script setup lang="ts">
import { AudienceTarget, AudienceType, ChannelType } from '@lasso/api-activation/activation'

import CategoryWrapper from './CategoryWrapper.vue'
import { useCategory } from './useCategory'
import CategoryItem from './CategoryItem.vue'

const { channelId, accountId, category, types, targets, disabled = false } = defineProps<{
  channelId: ChannelType
  accountId: number
  category: number | null
  types: AudienceType[]
  targets: AudienceTarget[]
  level: number
  disabled?: boolean
}>()

const { data, error, hasNextPage, requestNextPage, expanded } = useCategory({
  channelId: () => channelId,
  accountId: () => accountId,
  category: () => category,
  types: () => types,
  targets: () => targets,
})
</script>

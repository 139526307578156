<template>
  <SelectPanelItemDraggable
    :chip="chips"
    :label="item.name"
    :disabled="disabled"
    :info="info"
    :searchQuery="searchQuery"
  >
    <template v-if="hasSegmentationFilter" #extra>
      <SegmentationIndicator />
    </template>
  </SelectPanelItemDraggable>
</template>

<script setup lang="ts">
import { SelectPanelItemDraggable } from '@lasso/luikit'
import { AudienceInfo } from '@lasso/api-activation/activation'

import { useItemData } from './useItemData'
import SegmentationIndicator from './SegmentationIndicator.vue'

const { item, searchQuery, disabled = false } = defineProps<{
  item: AudienceInfo
  searchQuery: string
  disabled?: boolean
}>()

const { chips, info, hasSegmentationFilter } = useItemData(() => item)
</script>

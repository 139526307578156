<template>
  <Box flex col space="6" alignItems="start">
    <FormSection title="Select trigger source type">
      <FormField
        :id="getId('lassoTrigger.sourceTypeId')"
        v-slot="{ id, disabled }"
        :disabled="!isDraft"
        hideLabel
      >
        <Box flex space="6">
          <RadioButton
            v-for="option in triggerSourceTypeOptions"
            :key="option.value"
            v-model="sourceTypeId"
            data-test-id="triggers-source-type-radio"
            :name="id"
            :value="option.value"
            :disabled="disabled"
            :title="option.label"
          />
        </Box>
      </FormField>
    </FormSection>

    <SourceAdGroupSelector v-if="sourceTypeId" />

    <FormSection v-if="sourceTypeId" title="Select engagement">
      <Box flex col space="4">
        <FormField
          :id="getId('lassoTrigger.triggerEngagementId')"
          v-slot="{ id, disabled }"
          :disabled="!isDraft"
          width="220px"
        >
          <Select
            :id="id"
            :modelValue="lassoTrigger.triggerEngagementId || ''"
            :options="triggerEngagementIdOptions"
            :disabled="disabled"
            placeholder="Select"
            @update:modelValue="onUpdateEngagement($event)"
          />
        </FormField>

        <FormField
          v-if="lassoTrigger.triggerEngagementId === AdGroupTriggerEngagement.UN_OPEN"
          :id="getId('lassoTrigger.daysAfterEmailDelivered')"
          v-slot="{ id, disabled }"
          :disabled="!isDraft"
          hideLabel
        >
          <FormChoiceField :id="id" label="days after email delivered" :disabled="disabled">
            <InputText
              :id="id"
              v-model="lassoTrigger.daysAfterEmailDelivered"
              :mask="numberMask"
              :disabled="disabled"
              width="60px"
            />
          </FormChoiceField>
        </FormField>
      </Box>
    </FormSection>

    <CustomTriggerEngagements v-if="lassoTrigger.triggerEngagementId === AdGroupTriggerEngagement.CUSTOM_DYNAMIC" />
  </Box>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import {
  Box, FormChoiceField,
  FormField,
  FormSection,
  InputText,
  RadioButton,
  Select, buildSelectOptionsFromRecord, useSelectValue,
} from '@lasso/luikit'
import { AdGroupSourceType, AdGroupTriggerEngagement } from '@lasso/api-activation/activation'

import { numberMask } from '@lasso/shared/consts'

import { useCardAudiences } from '../useCardAudiences'

import SourceAdGroupSelector from './SourceAdGroupSelector.vue'
import CustomTriggerEngagements from './CustomTriggerEngagements.vue'

const {
  isDraft,
  lassoTrigger,
  addEngagement,
  getId,
} = useCardAudiences()!

const sourceTypeId = useSelectValue(toRef(lassoTrigger, 'sourceTypeId'), null)

const triggerSourceTypeOptions = computed(() => {
  return buildSelectOptionsFromRecord({
    [AdGroupSourceType.PROGRAMMATIC]: { label: 'Programmatic' },
    [AdGroupSourceType.EMAIL]: { label: 'Email' },
  } satisfies Record<AdGroupSourceType, { label: string; disabled?: boolean }>, 'label')
})

const triggerEngagementIdOptions = computed(() => {
  const options: Partial<Record<AdGroupTriggerEngagement, string>> = sourceTypeId.value === AdGroupSourceType.PROGRAMMATIC
    ? {
        [AdGroupTriggerEngagement.VIEW]: 'View',
        [AdGroupTriggerEngagement.CLICK]: 'Click',
        [AdGroupTriggerEngagement.CUSTOM_DYNAMIC]: 'Custom',
      }
    : {
        [AdGroupTriggerEngagement.UN_OPEN]: 'Un-Open',
        [AdGroupTriggerEngagement.EMAIL_OPEN]: 'Open',
        [AdGroupTriggerEngagement.EMAIL_CLICK]: 'Click',
      }

  return buildSelectOptionsFromRecord(options)
})

const onUpdateEngagement = (newTriggerAction: AdGroupTriggerEngagement) => {
  lassoTrigger.engagements = []
  lassoTrigger.triggerEngagementId = newTriggerAction

  if (lassoTrigger.triggerEngagementId === AdGroupTriggerEngagement.CUSTOM_DYNAMIC) {
    addEngagement()
  }
}
</script>

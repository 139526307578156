<template>
  <Box data-test-id="logic-condition-select">
    <DropdownList width="80px" tag="div" :disabled="disabled">
      <Chip
        :label="logicConditionsOptions[lassoTrigger.logicCondition]"
        variant="filled"
        color="primary"
        data-test-id="select-input-wrapper"
        :selectable="!disabled"
        :clickable="!disabled"
      />

      <template #dropdown="scope">
        <template v-if="scope.state.isVisible || scope.state.isShown">
          <ListItemButton
            v-for="{ value, label } in logicConditions"
            :key="value"
            data-test-id="select-option"
            @click="onChangeOperator(value, scope.hide)"
          >
            {{ label }}
          </ListItemButton>
        </template>
      </template>
    </DropdownList>
  </Box>
</template>

<script setup lang="ts">
import { Box, Chip, DropdownList, ListItemButton, buildSelectOptionsFromRecord } from '@lasso/luikit'

import { computed } from 'vue'

import { AudienceLogicConditions } from '../types'
import { useCardAudiences } from '../useCardAudiences'

const { disabled } = defineProps<{
  disabled: boolean
}>()

const { lassoTrigger } = useCardAudiences()!

const logicConditionsOptions: Record<AudienceLogicConditions, string> = {
  [AudienceLogicConditions.AND]: 'AND',
  [AudienceLogicConditions.OR]: 'OR',
}

const logicConditions = computed(() => {
  return buildSelectOptionsFromRecord(logicConditionsOptions)
    .filter(operator => operator.value !== lassoTrigger.logicCondition)
})

const onChangeOperator = (value: AudienceLogicConditions, hide: () => void) => {
  lassoTrigger.logicCondition = value
  hide()
}
</script>

<template>
  <Paper variant="outlined" rounded="xl" px="6" py="4" data-test-id="trigger-engagement-item">
    <Box flex wrap="wrap" space="4" class="relative">
      <ButtonIcon
        v-if="canDeleteEngagement"
        :disabled="disabled"
        icon="close"
        class="remove"
        data-test-id="trigger-engagement-item-delete"
        @click="onRemove()"
      />
      <FormField
        :id="getId(`lassoTrigger.engagements[${index}].action`)"
        v-slot="{ error, id }"
        :disabled="disabled"
        hideLabel
        width="105px"
      >
        <Select
          :id="id"
          v-model="actionInternal"
          :options="actionOptions"
          :disabled="disabled"
          :error="error"
          :name="id"
        />
      </FormField>
      <Box pt="2">
        <Typography variant="body2" whiteSpace="nowrap">
          the source ad
        </Typography>
      </Box>
      <FormField
        :id="getId(`lassoTrigger.engagements[${index}].quantity`)"
        v-slot="{ id }"
        :disabled="disabled"
        hideLabel
        width="105px"
      >
        <InputText
          :id="id"
          v-model="quantity"
          :disabled="disabled"
          :mask="numberMask"
          :name="id"
        />
      </FormField>
      <Box pt="2">
        <Typography variant="body2" whiteSpace="nowrap">
          times in
        </Typography>
      </Box>
      <FormField
        :id="getId(`lassoTrigger.engagements[${index}].periodValue`)"
        v-slot="{ id }"
        :disabled="disabled"
        hideLabel
        width="105px"
      >
        <InputText
          :id="id"
          v-model="periodValue"
          :disabled="disabled"
          :mask="numberMask"
          :name="id"
        />
      </FormField>
      <Box pt="2">
        <Typography variant="body2" whiteSpace="nowrap">
          days
        </Typography>
      </Box>
    </Box>
  </Paper>
</template>

<script setup lang="ts">
import { numberMask } from '@lasso/shared/consts'
import {
  Box,
  ButtonIcon,
  FormField,
  InputText,
  Paper,
  Select,
  Typography,
  buildSelectOptionsFromRecord, useSelectValue,
} from '@lasso/luikit'

import { AudienceEngagementAction } from '../types'
import { useCardAudiences } from '../useCardAudiences'

const { index } = defineProps<{
  index: number
  disabled: boolean
}>()

const {
  engagements,
  getId,
  useFieldArrayModels,
  canDeleteEngagement,
} = useCardAudiences()!

const {
  action,
  quantity,
  periodValue,
} = useFieldArrayModels(
  'lassoTrigger.engagements',
  ['action', 'quantity', 'periodValue'],
  () => index,
)

const actionInternal = useSelectValue(action, null)

const actionOptions = buildSelectOptionsFromRecord({
  [AudienceEngagementAction.Viewed]: 'Viewed',
  [AudienceEngagementAction.Clicked]: 'Clicked',
} satisfies Record<AudienceEngagementAction, string>)

const onRemove = () => {
  engagements.remove(index)
}
</script>

<style scoped>
.remove {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
</style>

<template>
  <FormSection :title="title">
    <SelectPanel :loading="fetching" :empty="empty" :listKey="selectedTypeInternal">
      <template #search>
        <SelectPanelInput v-model="apiSearch.searchQuery" />
      </template>
      <template #tabs>
        <Tabs v-model:active="selectedTypeInternal" variant="text" size="md">
          <TabsItem v-for="tab in availableTypes" :key="tab.value" :value="tab.value">
            {{ tab.label }}
          </TabsItem>
        </Tabs>
      </template>
      <template v-if="!fetching" #items>
        <Box v-if="error" flex justify="center" alignItems="center" grow="grow">
          <ErrorState :error="error" @retry="retry()" />
        </Box>
        <CategoryWrapper
          v-else-if="apiSearch.searching"
          :items="apiSearch.data"
          data-test-id="audiences-search"
          :hasNextPage="apiSearch.hasNextPage"
          :requestNextPage="apiSearch.requestNextPage"
        >
          <SearchItem
            v-for="item in apiSearch.data"
            :key="item.id"
            :item="item"
            :searchQuery="apiSearch.searchQueryFormatted"
            :disabled="maxAudiencesSelected"
          />
        </CategoryWrapper>
        <CategoryWrapper
          v-else
          :items="apiCategory.data"
          data-test-id="audiences-browser"
          :hasNextPage="apiCategory.hasNextPage"
          :requestNextPage="apiCategory.requestNextPage"
        >
          <CategoryItem
            v-for="item in apiCategory.data"
            :key="`${item.type}-${item.id}`"
            v-slot="{ nextLevel, nextCategory }"
            :item="item"
            :level="0"
            :expanded="apiCategory.expanded"
            :disabled="maxAudiencesSelected"
          >
            <Category
              :channelId="channelId"
              :accountId="accountId"
              :category="nextCategory"
              :types="selectedTypes"
              :targets="targets"
              :level="nextLevel"
              :disabled="maxAudiencesSelected"
            />
          </CategoryItem>
        </CategoryWrapper>
      </template>
    </SelectPanel>
  </FormSection>
</template>

<script setup lang="ts">
import {
  Box, ErrorState,
  FormSection,
  SelectPanel,
  SelectPanelInput,
  Tabs,
  TabsItem, buildSelectOptionsFromRecord, useSelectValue,
} from '@lasso/luikit'
import { computed, reactive, ref, toValue, watch } from 'vue'
import { arrayIncludes, truthy } from '@lasso/shared/utils'
import { useApiCombined } from '@lasso/shared/hooks'
import { AudienceTarget, AudienceType, ChannelType } from '@lasso/api-activation/activation'

import {
  SupportedAudienceType,
  audienceTypeLabels, audienceTypeToApiAudienceTypes, availableAudienceTypesByChannelId,
} from '../../../../shared'

import { useCardAudiences } from '../useCardAudiences'

import CategoryWrapper from './CategoryWrapper.vue'
import CategoryItem from './CategoryItem.vue'
import { useSearch } from './useSearch'
import Category from './Category.vue'
import { useCategory } from './useCategory'
import SearchItem from './SearchItem.vue'

const { withTitle } = defineProps<{
  withTitle: boolean
}>()

const {
  isDirectSold,
  channelId,
  accountId,
  maxAudiences,
  maxAudiencesSelected,
  isFeatureUniversalPixelsEnabled,
} = useCardAudiences()!

const title = computed(() => {
  if (!withTitle) {
    return undefined
  }

  return maxAudiences.value === 1 ? 'Select audience' : 'Select audiences'
})

const selectedType = ref<SupportedAudienceType | null>(null)
const selectedTypeInternal = useSelectValue(selectedType, null)

const allTypes = buildSelectOptionsFromRecord({ '': 'All', ...audienceTypeLabels })
const allAvailableTypes = computed(() => {
  return (availableAudienceTypesByChannelId[channelId.value] /* v8 ignore next */ ?? []).filter((type) => {
    switch (type) {
      case AudienceType.UNIVERSAL_PIXEL_AUDIENCE_CONVERSION:
        return toValue(isFeatureUniversalPixelsEnabled)

      default:
        return true
    }
  })
})

const availableTypes = computed(() => {
  return allTypes.filter(type => !type.value || arrayIncludes(allAvailableTypes.value, type.value))
})

const selectedTypes = computed(() => {
  const types = selectedType.value
    ? [selectedType.value]
    : availableTypes.value.map(type => type.value).filter(truthy)

  return types.flatMap(type => audienceTypeToApiAudienceTypes[type] /* v8 ignore next */ ?? [])
})

watch(channelId, () => {
  if (!arrayIncludes(allAvailableTypes.value, selectedType.value)) {
    selectedType.value = null
  }
})

const targets = computed(() => {
  switch (channelId.value) {
    case ChannelType.ENDEMIC_DTC:
      return [AudienceTarget.PATIENT_CONSUMER, AudienceTarget.CAREGIVER]
    case ChannelType.ENDEMIC_HCP:
    case ChannelType.ENDEMIC_EHR:
      return [AudienceTarget.HEALTHCARE_PROVIDER]
    default:
      return isDirectSold ? [AudienceTarget.HEALTHCARE_PROVIDER] : []
  }
})

const apiSearch = reactive(useSearch({
  channelId,
  accountId,
  types: selectedTypes,
  targets,
}))

const apiCategory = reactive(useCategory({
  channelId,
  accountId,
  category: () => null,
  types: selectedTypes,
  targets,
  enabled: () => !apiSearch.searching,
}))

const { fetching, error, retry } = useApiCombined([apiSearch, apiCategory])

const empty = computed(() => {
  if (fetching.value || error.value) {
    return false
  }

  return apiSearch.searching ? apiSearch.data.length === 0 : apiCategory.data.length === 0
})
</script>

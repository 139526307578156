<template>
  <SelectPanelItemDraggable
    :chip="chips"
    :label="item.name"
    :disabled="isCategory || disabled"
    :expandable="isCategory"
    :level="level"
    :expanded="expanded"
    :info="info"
  >
    <template v-if="isCategory" #default="{ nextLevel }">
      <slot v-bind="{ nextLevel, nextCategory: item.id }" />
    </template>
    <template v-if="hasSegmentationFilter" #extra>
      <SegmentationIndicator />
    </template>
  </SelectPanelItemDraggable>
</template>

<script setup lang="ts">
import {
  SelectPanelItemDraggable,
} from '@lasso/luikit'
import { AudienceCategoryInfo, AudienceInfo } from '@lasso/api-activation/activation'
import { computed } from 'vue'

import { getIsCategory } from './utils'
import { useItemData } from './useItemData'
import SegmentationIndicator from './SegmentationIndicator.vue'

const { item, level, expanded = false, disabled = false } = defineProps<{
  item: AudienceInfo | AudienceCategoryInfo
  level: number
  expanded?: boolean
  disabled?: boolean
}>()

const isCategory = computed(() => getIsCategory(item))

const { chips, info, hasSegmentationFilter } = useItemData(() => item)
</script>
